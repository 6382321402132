import React from 'react';
import Image from './image';
import './fs.scss';

const FS = () => (
  <div className="groomer-page__fs">
    <div className="groomer-page__fs-img-wrap">
      <Image className="about-page__fs-img" />
    </div>
    <div className="groomer-page__fs-body">
      <div className="groomer-page__fs-title page__title page__title_h1">Сергей Крутов</div>
      <div className="groomer-page__fs-text page__title page__title_h2">
        Я с детства любил собак и мечтал стать парикмахером. Поэтому, когда узнал о профессии грумера, сразу понял кем
        хочу быть. Сейчас я занимаюсь уходом за собаками и очень этому рад.
      </div>
    </div>
  </div>
);

export default FS;
