import React from 'react';
import PropTypes from 'prop-types';
import Image from './image';

const ImageWrap = (props) => {
  const { name } = props;
  return (
    <div className="portfolio__slide-img-wrap">
      <Image className="portfolio__slide-img" name={name} />
    </div>
  );
};

ImageWrap.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ImageWrap;
