import React from 'react';
import PropTypes from 'prop-types';
import Image from './image';
import Button from '../../buttons/button/button';
import './subscribe.scss';

const Subscribe = (props) => {
  const {
    photo,
    title,
    groomerId,
  } = props;

  const handleClick = () => {
    groomerId.toString();
  };

  return (
    <div className="groomer-page__subscribe">
      <div className="groomer-page__subscribe-wrap">
        <div className="groomer-page__subscribe-photo"><Image name={photo} /></div>
        <div className="groomer-page__subscribe-body">
          <div className="groomer-page__subscribe-title page__title page__title_h3">{title}</div>
          <Button
            className="groomer-page__subscribe-button"
            theme="red"
            onClick={handleClick}
          >
            Записаться
          </Button>
        </div>
      </div>
    </div>
  );
};

Subscribe.propTypes = {
  photo: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  groomerId: PropTypes.number.isRequired,
};

Subscribe.defaultProps = {
  photo: '1.png',
  title: (
    <>
      Запишитесь
      <span>к Сергею</span>
    </>
  ),
};

export default Subscribe;
