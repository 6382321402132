import React from 'react';

// todo alex create reviews
const Reviews = () => (
  <div className="reviews">
    <div className="title" />
    <div className="list">
      <div className="item">
        <div className="review">
          <div className="title" />
          <div className="text" />
          <div className="avatar" />
        </div>
      </div>
    </div>
  </div>
);

export default Reviews;
