import React from 'react';
import { Arrow } from '../../icons/icons';
import './portfolio.scss';
import ImageWrap from './image-wrap';

const ArrowLeft = Arrow.left;
const ArrowRight = Arrow.right;

// todo alex create portfolio

const Portfolio = () => (
  <div className="portfolio">
    <div className="groomer-page__title portfolio__title page__title page__title_h2">Портфолио грумера</div>

    <div className="portfolio__arrows">
      <button type="button" className="portfolio__arrow portfolio__arrow_left">
        <ArrowLeft />
      </button>
      <button type="button" className="portfolio__arrow portfolio__arrow_right">
        <ArrowRight />
      </button>
    </div>

    <div className="portfolio__slider">
      <div className="portfolio__slide">
        <ImageWrap name="" />
      </div>
    </div>
  </div>
);

export default Portfolio;
