import React from 'react';
import StandardPage from '../components/page/page-standard';
import '../components/groomer-page/groomer-page.scss';
import Reviews from '../components/groomer-page/reviews/reviews';
import Subscribe from '../components/groomer-page/subscribe/subscribe';
import Portfolio from '../components/groomer-page/portfolio/portfolio';
import FS from '../components/groomer-page/fs/fs';

const pageName = 'groomer-page';

const GroomerPage = () => (
  <StandardPage pageName={pageName} backHref="/">
    <FS />
    <Portfolio />
    <Reviews />
    <Subscribe groomerId={1} />
  </StandardPage>
);

export default GroomerPage;
